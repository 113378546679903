
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";

export default Vue.extend({
  name: "Create",

  data: () => ({
    model: {} as any,
    disableSave: true as boolean,
    fullLoaded: false as boolean,
    errorMessages: {} as any,
    rules: {
      required: [
        (v: string): boolean | string =>
          !!v || "Поле обязательно для заполнения!"
      ]
    }
  }),

  watch: {
    model: {
      deep: true,
      handler() {
        if (this.fullLoaded) {
          this.disableSave = false;
        }
      }
    }
  },

  computed: {
    computedRules(): object {
      return {
        min: this.minLength
      };
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const item = await API.roles().getForEdit(Number(to.params.id));

      next(vm => {
        vm.model.name = item.name;
        vm.$nextTick(() => {
          vm.fullLoaded = true;
        });
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    minLength(number: number): Function {
      return (v: string): boolean | string =>
        v.length >= number || `Минимальная длина: ${number}`;
    },
    async submit(): Promise<void> {
      try {
        if ((this.$refs.form as any).validate()) {
          await this.$API
            .roles()
            .edit(this.$route.params.id as any, this.model);
          await this.$router.push("/roles");
          await this.$store.dispatch(
            "alert/showSuccess",
            "Запись успешно обновлена."
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
