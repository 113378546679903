
import Vue from "vue";
import MultiplePhonePicker from "@/components/form-components/MultiplePhonePicker.vue";
import FileUploader from "@/components/form-components/FileUploader.vue";
import API from "@/api/API";
import store from "@/store";

export default Vue.extend({
  name: "Create",

  components: {
    FileUploader,
    MultiplePhonePicker
  },

  data: () => ({
    model: {
      name: "" as string,
      full_name: "" as string,
      login: "" as string,
      password: "" as string,
      email: "" as string,
      password_confirmation: "" as string,
      commission: "" as string,
      position_id: null as any,
      phone: []
    } as any,
    items: [] as any,
    errorMessages: {} as any
  }),

  computed: {
    computedRules(): object {
      return {
        required: this.required,
        min: this.minLength,
        maxNumber: this.max,
        minNumber: this.min
      };
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const item = await API.users().getForEdit(to.params.id);
      item.phone = item.phone?.split(",");
      const roles = await API.roles().getList();

      if (!item.avatar) {
        item.avatar = {
          name: "",
          url: ""
        } as any;
      }

      next(vm => {
        vm.model = item;
        vm.items = roles;

        vm.$nextTick(() => {
          vm.fullLoaded = true;
        });
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    required(): Function {
      return (v: string): boolean | string | any =>
        !!v || this.$t("global_validation.required");
    },
    minLength(number: number): Function {
      return (v: string): boolean | string | any =>
        (!!v && v.length >= number) ||
        this.$t("global_validation.min", { number });
    },
    max(number: number): Function {
      return (v: string): boolean | string | any =>
        !v ||
        Number(v) <= number ||
        this.$t("global_validation.max", { number });
    },
    min(number: number): Function {
      return (v: string): boolean | string | any =>
        !v ||
        Number(v) >= number ||
        this.$t("global_validation.min", { number });
    },
    async getForEdit(): Promise<void> {
      try {
        this.items = await this.$API.roles().getList();
        const response = await this.$API
          .users()
          .getForEdit(this.$route.params.id);

        this.model = { ...response, phone: response.phone?.split(",") };

        if (!this.model.avatar) {
          this.model.avatar = {
            name: "",
            url: ""
          } as any;
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async submit(): Promise<void> {
      try {
        if ((this.$refs.form as any).validate()) {
          const model = { ...this.model };
          model.avatar = model.avatar.name;
          model.phone = model.phone.join(",");

          for (const key in model) {
            if (key === "avatar" && typeof model[key] === "string") {
              delete model[key];
            }
          }
          await this.$API.users().edit(this.$route.params.id, model);
          await this.$router.push("/users");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_edit")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
